<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary ml-1">
            Lordtech
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Adventure starts here 🚀
        </b-card-title>
        <b-card-text class="mb-2">
          Make your app management easy and fun!
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm"    #default="{invalid}">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >


           <!-- username -->
            <b-form-group
            v-if="refavaila"
              label="Refered By"
              label-for="username"
            >
             
                <b-form-input
                  id="username"
                
                  v-model="refuser"
 readonly
             
                  name="register-username"
                  placeholder="mrfash"
            
                />
               
           
            </b-form-group>


            <!-- name -->
            <b-form-group
              label="Full Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"

              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                  name="name"
                  placeholder="charles"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>




          

            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false:null"
                  name="email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>



           <!-- username -->
            <b-form-group
              label="Username"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  @keydown.space.prevent
                  @keyup="trimusername"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="register-username"
                  placeholder="mrfash"
            
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
           <!-- email -->
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="regPhone"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                  name="phone"
                  placeholder="08105450501"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>



            <!-- password -->
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
           
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>






            <b-form-group
              label="Confirm Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="confirm-password"
                    v-model="confirmpassword"
            
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
   

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                I agree to
                <b-link>privacy policy & terms</b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
                :disabled="invalid || load"
            >

               <b-spinner v-if="load"
          small
          type="grow"
        />  
              Sign up
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>

    
      
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle,  BSpinner, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required,  email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as moment from 'vue-moment'
export default {

  props: ['user'],
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
     BSpinner,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      load: false,
      name: '',
      regEmail: '',
      regPhone: '',
      username: '',
      refavaila: false,
      refuser: null,
      password: '',
      confirmpassword: '',
      status: '',
  

      // validation rules
      required,
 
      email,
    }
  },


  mounted() {
    if(this.user != undefined) {
       localStorage.setItem('userref', JSON.stringify({username: this.user, date: this.timestamp() }))
      console.log(this.user)

       this.$router.push({name: 'register'});
    }
    this.referrealset();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
   watch: {
    username(val) {
      this.username = val.replace(/\W/g, "");


      console.log(this.username);
    },
  },
  methods: {    
      timestamp() {
   return this.$moment().format('YYYY-MM-DD HH:mm:ss'); 
  },


  referrealset(){


    if (localStorage.hasOwnProperty('userref')) {

      var userref = JSON.parse(localStorage.getItem('userref'));  
const pastTime = new Date(userref.date);

const now = new Date();

const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

const timeDiffInMs = now.getTime() - pastTime.getTime();


if(timeDiffInMs >= sevenDaysInMs){
    localStorage.removeItem('userref');
}else{
   
   this.refuser = userref.username;
   this.refavaila = true;
}




      






    }





  },
        trimusername() {
        this.username = this.username.toLowerCase().trim();
    },
   async register() {

     
this.load = true;


     var data = await this.$store.dispatch('register', {
         email: this.regEmail,
        password: this.password,
        password_confirmation: this.confirmpassword,
        name: this.name,
        username: this.username,
        phone: this.regPhone,
        refuser: this.refuser,
      });  
  
if (data.status == true) {
  this.load = false;


this.$router.push({name: 'dashboard'});
  this.$swal({
        title: 'Account Created Successfully',
        text : "Please Verify Your Email Account To Have Access To This Email Account",
       
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    
  
}else {

    console.log( Object.prototype.toString.call(data.message))
  if (data.object = true) {
       var data = JSON.parse(data.message)
       console.log(data);
    this.load = false;
    for (var k in data) {
        if (data.hasOwnProperty(k)) {

            this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    




  }else {

this.load = false;

       this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message,

                    },
                  })

  }
}





 

   },





   async validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
        this.register();
      
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
